/* CARD PRODUCT */
@media screen and (min-width: 1200px) {
    .card-product {
        height: 225px !important;
        min-width: 320px !important;
    }

    .product-image {
        top: -7rem !important;
    }

    .product-image img {
        width: 75%;
        max-width: 350px !important;
    }
}

@media screen and (min-width: 968px) and (max-width: 1200px) {
    .card-product {
        height: 200px !important;
        min-width: 320px !important;
    }

    .product-image {
        top: -5rem !important;
    }

    .product-image img {
        width: 75%;
        max-width: 250px !important;
    }
}

@media screen and (max-width: 968px) {
    .card-product {
        height: 200px;
        min-width: 320px;
    }

    .product-image {
        top: -5.5rem;
    }

    .product-image img {
        width: 75%;
        max-width: 275px;
    }
}

.card-product {
    position: relative;
    background-color: var(--app-color-bg-1);
    border-radius: 10px;

}

.product-image {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;

    width: 100%;
}

.product-image img {
    width: 75%;
}

.card-product .product-text {
    text-align: center;
    margin: 0 auto;
    position: absolute;
    bottom: 1rem;
    right: 0;
    left: 0;
}

.card-product .product-text .text-title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 38px;
}

.card-product .product-text .text-description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    color: var(--app-color-text-5);
    padding: 0 5px;
}

/* CARD IMAGE */
.card-image.grey {
    background: #F1F1F3;
    padding: 1rem;
}

.card-image .image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-image .image img {
    width: auto;
    max-width: 150px;
    max-height: 7rem;
}

.card-image .image img.lg {
    width: 100%;
    height: 100%;
    max-height: 192px;
    max-width: 192px;
    margin-bottom: 16px;
}

.card-image .image img.circle {
    border-radius: 50%;
}

.card-image .image img.sm {
    width: 35%;
    max-width: 75px;
}

.card-image .image img.ssm {
    width: 25%;
    max-width: 35px;
}

.card-image .content-title {
    padding-top: 1rem;
    color: #3A3A3A;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    font-weight: 700;
}

.card-image .content-title--extra {
    color: #939393;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.card-image .content-description {
    padding-top: 1rem;
    color: var(--app-color-text-5);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}


/* CARD STATISTIC */
.card-statistic {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.card-statistic .card-statistic--image {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.card-statistic .card-statistic--content {
    width: 80%;
}

.card-statistic .card-statistic--image img {
    max-width: 4.65rem;
    max-height: 4.65rem;
}

.card-statistic .card-statistic--title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 38px;
    color: #000000;
}

.card-statistic .card-statistic--description {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 29px;
    color: var(--app-color-text-4);
    text-transform: capitalize;
}

/* CARD NEWS */
.card-news--title {
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: var(--app-color-primary-dark);
    padding-top: 1rem;
}

.card-news--description {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: var(--app-color-text-5);
    padding-top: 0.5rem;
}

/* CARD MEMBER */
.card-member.has-border {
    border: 1px solid #969696;
    border-radius: 8px;
}

.card-member--content {
    padding-top: 1rem;
    text-align: center;
}

.card-product--content {
    padding: 1rem;
    text-align: left;
    min-height: 207px;
}

.card-member--name {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    color: var(--app-color-primary-dark);
}

.card-product--name {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    color: var(--app-color-primary-dark);
}

.card-member--position {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--app-color-text-2);
}

.card-product--position {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #6B6B6B;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* CARD ICON */
.card-icon img {
    max-width: 50px;
}

.card-icon .card-icon--title {
    font-size: 20px;
    line-height: 23px;
    color: var(--app-color-primary-dark);
    margin: 0;
}

.card-icon .card-icon--title .strong-title {
    font-weight: 700;
}

.card-image-fw {
    padding: 0 !important;
    position: relative;
}

.card-image-fw .image img {
    width: 100%;
    max-width: unset !important;
    max-height: unset !important;
}

.card-image-fw .content {
    position: absolute;
    bottom: 16px;
    width: 100%;
}

.card-image-fw .content .content-title {
    /* H2/32/Bold */

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    /* White */

    color: #FFFFFF;
}

.card-image-fw .content .content-description {
    /* Body/18/Medium */

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    text-align: center;

    /* Light Grey */

    color: #969696;
}

.cardPost-mobile .card-post {
    width: 560px !important;
}

.cardPost-desktop .card-post {
    width: 33% !important;
}

.card-image-flex {
    display: flex;
}

.card-image-flex .image img {
    width: 48px !important;
}

.card-image-flex .content {
    margin-left: 16px;
}

.card-image-flex .content .content-title {
    padding: 0;
}

.card-image-flex .content .content-title--extra {
    text-align: left;
}

.card-member--image img {
    max-height: 177px;
    object-fit: cover;
}

.card-news {
    padding-bottom: 8px;
}

@media only screen and (max-width: 600px) {
    .card-news--title {
        font-size: 1.5rem;
    }
}


/* CARD ORDER */
.card-my-order {
    border-color: var(--app-color-primary-dark) !important;
}

.card-my-order .card-title {
    font-size: 24px;
    line-height: 29px;
    color: var(--app-color-primary-dark);
    /* border-bottom: 1px solid var(--app-color-primary-dark); */
    padding-bottom: 1rem;
}

.card-my-order .card-footer {
    border-top: 1px solid #E7EAEF;
    padding-top: 1rem;
    background-color: #FFFFFF;
    margin: 0 0.75rem;
    display: flex;
}

.card-my-order .card-footer .btn-container {
    width: 50%;
}

.card-my-order .card-footer .total-order {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    align-items: center;
}
