/* @import url("./bootstrap/bootstrap-grid.min.css"); */
@import url("./icon/icofont/icofont.min.css");

@import url("./module/layout.css");
@import url("./module/button.css");
@import url("./module/list.css");
@import url("./module/social.css");
@import url("./module/sideNav.css");
@import url("./module/form.css");
@import url("./module/banner.css");
@import url("./module/card.css");
@import url("./module/row.css");
@import url("./module/section.css");
@import url("./module/collapse.css");
@import url("./module/table.css");
@import url("./module/widget.css");
@import url("./module/loading.css");
@import url("./pages/pages.css");
@import url("./pages/cartPage.css");
@import url("./pages/braintree.css");
@import url("./pages/getInstantQuote.css");
@import url("./pages/myOrder.css");

@font-face {
  font-family: 'barlow';
  src: url('./font-face/Barlow/Barlow-Regular.ttf');
}

@font-face {
  font-family: 'barlow-bold';
  src: url('./font-face/Barlow/Barlow-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'barlow-semi';
  src: url('./font-face/Barlow/Barlow-SemiBold.ttf');
}

@font-face {
  font-family: 'barlow-medium';
  src: url('./font-face/Barlow/Barlow-Medium.ttf');
}


/* // Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */

:root {
  --app-color-primary-dark: #312F30;
  --app-color-primary-blue: #0076E2;
  --app-color-primary-white: #fff;
  --app-color-text-1: #000000;
  --app-color-text-2: #222222;
  --app-color-text-3: #4E4E4E4E;
  --app-color-text-4: #646464;
  --app-color-text-5: #6B6B6B;
  --app-color-bg-1: #EBEBEB;
  --app-color-bg-2: #F5F5F5F5;
  --app-color-bg-3: #4E4E4E;
  --app-color-bg-4: #646464;
  --app-color-bg-5: #6B6B6B;
  --app-color-link: #CECECE;
  --app-color-blue: #0076E2;
  --app-color-orange: #FF6B00;
  --app-color-green: #00880E;
  --app-color-red: #F00202;
  --app-color-light-gray-1: #969696;
  --app-color-light-gray-2: #D9D9D9;
  --app-color-btn-primary: #0076E2;
  --app-color-btn-secondary: #f2f2f2
}

html,
body {
  font-family: barlow !important;
  max-width: 100%;
  overflow-x: hidden;
}

html {
  font-size: 16px;
  color: #312F30;
  font-family: barlow;

}

h1 {
  font-size: 48px;
  font-family: barlow-bold;
}

h2 {
  font-size: 32px;
  font-family: barlow-bold, barlow-semi !important;
}

h3 {
  font-size: 24px;
  font-family: barlow-semi !important;
  font-weight: 600;
  color: #000;
}

h4 {
  font-size: 18px;
  font-family: barlow, barlow-bold !important;
}

body {
  font-family: barlow !important;
  font-size: 18px;
}

body:has(.backdrop),
html:has(.backdrop),
html:has(.offcanvas-backdrop) {
  overflow: hidden;
}

small {
  font-size: 16px;
}

caption {
  font-size: 12px;
}

button {
  font-family: barlow;
}

img {
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
}

a {
  color: var(--app-color-primary-blue);
  font-family: barlow;
  font-style: normal;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  text-decoration: none !important;
  transition: 0.25s;
}

.MuiTypography-root {
  color: var(--app-color-primary-dark);
  font-family: barlow !important;
}

a:hover {
  font-weight: 800;
}

a.hover-not-bold {
  font-size: inherit;
  text-align: inherit;
}

a.hover-not-bold:hover {
  font-weight: normal;
  font-size: inherit;
}

.link {
  color: var(--app-color-primary-blue);
  cursor: pointer;
}

button.link a {
  color: inherit;
  font-weight: normal;
  text-decoration: unset;
  font-size: inherit;
}

button.link a:hover {
  font-weight: normal;
  text-decoration: unset;
}

/* Color */
.color-orange {
  color: var(--app-color-orange);
}

.color-text-5 {
  color: var(--app-color-text-5);
}




/* CUSTOM LIBRARY REACT_SLICK */
.slick-slider .slick-arrow::after,
.slick-slider .slick-arrow::before {
  color: var(--app-color-text-2);
}

.slick-initialized .slick-slide {
  padding: 1.5rem
}

/* CUSTOM COMPONENT  */
.list-item a {
  color: var(--app-color-primary-blue) !important;
  text-decoration: unset !important;
}

.choose-product table {
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  font-family: Barlow;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  background: white;
}

.table-extra {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(150, 150, 150);
}

.action button {
  font-weight: 600;
  font-size: 20px;
}

.text-light-bold {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: rgb(100, 100, 100);
}

.post-content {
  padding: 60px 0 0 0;
}

.post-content b {
  font-weight: 600;
  font-size: 18px;
  line-height: 200%;
  color: #242424;
}

.post-content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */


  /* Grey */

  color: #6B6B6B;
}

.post-content .media {
  margin-bottom: 62px;
}

.media article {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  /* Light Grey */

  color: #969696;
}

.post-content h1 {
  text-align: center;
}

.BaseIntroduce .intro {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #312F30;
  margin-bottom: 48;
}

.BaseIntroduce h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */


  /* Black */

  color: #312F30;
}

.BaseIntroduce p {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */


  /* Grey */

  color: #6B6B6B;

}

.BaseIntroduce .section {
  padding-top: 48px;
}

/* CUSTOM NAVLINK */
.link-unactive {
  color: white !important;
}

.zIGoD {
  width: 100%;
  cursor: pointer;
}

.collapse.show {
  visibility: visible !important;
}

/* .app-container {
  margin-left: calc(50% - 720px);
  margin-right: calc(50% - 720px);
} */

@media screen and (min-width: 768px) {
  .app-container {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 768px) {
  .app-container {
    padding: 1.25rem 0;
  }
}

.offcanvas .offcanvas-title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 29px;
  color: var(--app-color-text-1);
}

@media screen and (max-width: 400px) {
  .offcanvas.offcanvas-start.show {
    border-right: 0;
  }

  .offcanvas.offcanvas-end.show {
    border-left: 0;
  }
}

.post-container {
  padding: 0 12rem;
}

.nav-menu .list-item .dropdown {
  position: static;
}

.nav-menu .list-item {
  padding-top: 19px;
  margin-bottom: -8px;
  padding-bottom: 8px;
  position: relative;
}

.nav-menu .list-item .dropdown-menu {
  left: 0 !important;
  top: 100% !important;
  transform: unset !important;
}

.nav-menu .list-item:hover .dropdown-menu {
  display: block !important;
  left: 0 !important;
  top: 100% !important;
  transform: unset !important;
}

.text-title__normal {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #312F30;
}

.text-para__normal__grey {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #6B6B6B;

}

/* page loading */
.loading-backdrop {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0px;
  top: 0;
  background: #000;
  opacity: 0.5;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100%
}

.loading-backdrop .close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #eee;
  font-size: 32px;
  cursor: pointer;
}

.loading-backdrop .close:hover {
  color: #fff;
}

.loading-backdrop .description {
  font-size: 1.5rem;
}

/* overlay loading */
.loading-overlay {
  position: relative;
}

.loading-icon {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  left: 0;
  top: 10%;
  bottom: 0;
}

.loading-body.active {
  opacity: 0.1;
  pointer-events: none;
}

/* CUSTOM FOR SELECT */
.app-select .MuiPaper-root {
  max-height: 400px !important;
}

.app-select .app-fixed-top {
  padding-top: 0.5rem;
}

.input-file>label {
  position: static !important;
}

.input-file>label>input {
  position: absolute !important;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.line-ellipsis-4 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  min-height: 96px;
}

#fb-root .fb_dialog_content iframe {
  bottom: 108px !important;
}

.ant-image-preview-img {
  width: auto;
}
