.list-title {
    font-weight: 600;
    color: var(--app-color-bg-1);
    margin: 0;
    padding-bottom: 0.25rem;
    font-size: 16px;
}

ul.list {
    list-style: none;
    margin: 0;
}

ul.list-vertical {
    padding: 0;
}

ul.list-vertical .list-item {
    padding: 0.45rem 0;
}


ul.list.list-horizontal {
    display: flex;
    gap: 3rem;
    list-style: none;
}

ul.list.list-item {
    line-height: 140%;
}

.app-footer ul.list-vertical .list-item {
    color: var(--app-color-link);
}

/* LIST DESCRIPTION */
.list .list-item.list-description:first-child {
    margin-top: 0;
}

.list .list-item.list-description {
    padding: 1rem;
    border-radius: 1px;
    margin: 0.75rem 0;
    border-radius: 5px;
}

.list-description.active {
    background: #4E4E4E;
}

.list-description--title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 29px;
    padding-bottom: 0.5rem;
}

.list-description--information {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #CCCCCC;
}

/* LIST INFORMATION */
.list-information .list-information--title {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 29px;
    color: var(--app-color-text-1);
    border: 0 !important;
}

.list-information .list-group-item {
    border: 1px solid #E1E4E9;
    padding: 1rem 0;
    align-items: center;
    font-family: 'barlow';
    gap: 1rem;
}

.list-information .list-group-item .list-item--label {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 140%;
    color: var(--app-color-text-5);
    font-family: 'barlow';
}

.list-information .list-group-item .list-item--content {
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 140%;
    color: var(--app-color-text-1);
    font-family: 'barlow';
}

.list-information .list-group-item .list-item--action {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 140%;
    text-align: right;
    color: #0076E2;
    font-family: 'barlow';
}

@media screen and (min-width: 468px) {
    .list-information .list-group-item {
        display: flex;
    }

    .list-information .list-group-item .list-item--label {
        width: 30%;
    }

    .list-information .list-group-item .list-item--content {
        width: 60%;
    }

    .list-information .list-group-item .list-item--action {
        width: 10%;
    }
}

.list-information .list-group-item a {
    text-decoration: none;
    font-weight: 700;
}

/* LIST CART */
.list-cart .list-group-item {
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
}

.list-cart .list-group-item:hover {
    background-color: #fafafa;
}

.list-cart .list-group-item .thumbnail {
    width: 10%;
}

.list-cart .list-group-item .thumbnail.large {
    width: 30%;
}

.list-cart .list-group-item .thumbnail img {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    max-width: 100px;
}

.list-cart .list-group-item .product {
    width: 60%;
    padding: 0 0.75rem;
}

.list-cart .list-group-item .small-price {
    display: flex;
    justify-content: flex-end;
    font-weight: 650;
    width: 30%;
}

.list-cart .list-group-item .list-description {
    list-style: none;
    padding: 0;
    color: var(--app-color-text-5);
}

/* LIST SELECT */
/*
.list-select .active {
    background-color: #0d6efd40 !important;
    color: var(--app-color-text-1) !important;
    margin: auto -16px;
    padding: 8px 16px !important;
    border-radius: 0 !important;

} */

.list-select .default {
    background-color: #FFE9D9;
    color: var(--app-color-orange);
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 4px;

}

.list-select .list-group-item {
    color: var(--app-color-text-5);
    /* cursor: pointer; */
    margin: 4px 0;

}

.list-select .list-group-item .link-modal {
    color: var(--app-color-blue);
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
}

.active-payment-method {
    /* background-color: #0d6efd40 !important;
    color: var(--app-color-text-1) !important; */
}

.text-default {
    padding: 4px 8px;
    text-align: center;
    border-radius: 6px;
    color: #FF6C00 !important;
    background-color: #FFE9D9;
    min-width: 85px;
}

.text-not-default {
    padding: 4px 8px;
    border-radius: 6px;
    text-align: center;
    color: #4b5563 !important;
    background-color: #f3f4f6;
    min-width: 85px;
}

@media screen and (max-width: 467px) {
    .list-information .list-group-item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 0;
    }

    .list-information .list-group-item .list-item--label {
        width: 100%;
    }

    .list-information .list-group-item .list-item--content {
        width: 100%;
    }

    .list-information .list-group-item .list-item--content.has-action {
        width: 75%;
    }

    .list-information .list-group-item .list-item--action {
        width: 25%;
    }
}
