.braintree-module .button {
  cursor: pointer;
  font-weight: 500;
  left: 3px;
  line-height: inherit;
  position: relative;
  text-decoration: none;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
}

.braintree-module .button--small {
  padding: 10px 20px;
  font-size: 0.875rem;
}

.braintree-module .button--green {
  outline: none;
  background-color: #64d18a;
  border-color: #64d18a;
  color: white;
  transition: all 200ms ease;
}

.braintree-module .button--green:hover {
  background-color: #8bdda8;
  color: white;
}
