/* login */
.form-item-input .forgot-password {
    color: #F00202;
    font-size: 16px;
    text-align: right;
    text-decoration: none;
    font-weight: 500;
    line-height: 19px;
}

.signup {
    font-weight: 700;
    font-size: 18px;
    line-height: 25.2px;
    font-family: barlow;
}

.title-description h3 {
    margin-bottom: 0
}

.title-description p {
    margin-top: 0.5rem;
}

.carousel-item {
    overflow: hidden;
}

/* homepage */

@media screen and (max-width: 992px) {
    .home-product .container {
        max-width: 500px;
        justify-content: center;
    }

    .home-v1hub-works .container {
        max-width: 500px;
    }

    .companies,
    .members {
        padding: 4rem
    }

    .v1hub-our-experience .b2c-container .row .col-md-12 {
        text-align: center;
    }

    .v1hub-our-experience .card-statistic {
        display: block;
    }

    .v1hub-our-experience .card-statistic .card-statistic--image,
    .v1hub-our-experience .card-statistic .card-statistic--content {
        width: 100%;
        margin: 0.75rem 0;
    }

    .banner .banner-text {
        text-align: center;
    }

    .banner .description-small {
        margin-left: auto;
        margin-right: auto;
    }

    .cardPost-desktop {
        display: none;
    }

    .cardPost-mobile {
        display: block;
    }

    .section-upload-part button,
    .productDescription button {
        width: 100%;
    }

    .introducePrinting .title,
    .introducePrinting .extra {
        padding: 1rem 0 !important;
    }

    .availableResource .title,
    .availableResource .extra {
        padding: 1rem 0 !important;
    }

    .guidelines .title,
    .guidelines .extra {
        padding: 1rem 0 !important;
    }

    .post-container {
        padding: 0 2rem !important;
    }

    .button {
        padding: 8px 24px !important;
    }

    .section {
        /* padding: 0 .5rem;; */
    }
}




/* CSS FOR PAGE BANNER */
@media screen and (min-width: 992px) {
    .page-banner {
        max-height: 466px;
    }

    .cardPost-desktop {
        display: block;
    }

    .cardPost-mobile {
        display: none;
    }
}
