.banner-wrapper {
    background-color: var(--app-color-primary-dark);
}


.banner-image img {
    max-width: 100%;
}

.banner .title-light {
    display: block;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
}

.banner .title-black {
    display: block;
    font-weight: 700;
    font-size: 54px;
    color: black;
    margin-bottom: 5px;
}

.banner .title-dark {
    display: block;
    font-weight: 700;
    font-size: 54px;
    line-height: 100%;
}

.banner .description {
    margin-top: 3rem;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}

.banner .description-small {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #C4C4C4;
}

/* layout */
@media screen and (max-width: 992px) {
    .banner-text {
        color: var(--app-color-primary-white);
        padding: 1.5rem;
        width: 100%;
    }

    .banner-text .action button {
        margin: auto;
    }

    .banner-image {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .banner-image img {
        width: 50%;
    }

    .banner .title-light,
    .banner .title-black,
    .banner .title-dark {
        font-size: 30px;
    }
}

@media screen and (min-width: 992px) {
    .banner {
        display: flex;
        align-items: center;
        gap: 2rem;
        height: 734px;
        /* background-color: var(--app-color-primary-dark); */
    }

    .banner-text {
        color: var(--app-color-primary-white);
        width: 55%;
        /* padding: 0 4.5rem; */
    }

    .banner-image {
        display: flex;
        justify-content: right;
        width: 35%;
    }
}
