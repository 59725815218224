.primary-btn {
    background: #0076E2;
    color: #fff
}

.table-cart {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}

.table-cart thead {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 140%;
    color: #6B6B6B;
    border-color: #fff;
    /* border-bottom: 1px solid #E1E4E9; */
    border-radius: 5px;
}

.table-cart tbody tr td img {
    max-width: 100px;
}

/* .table-cart tbody tr th, */
.table-cart tbody tr td {
    border: 1px solid #E1E4E9;
}

.table-cart thead tr th {
    border: unset !important;
    color: #6b6b6b !important;
    background: unset !important;
}

.table-cart thead tr th::before {
    background: unset !important;
}

/* .table-cart tbody tr th:not(:first-child), */
.table-cart tbody tr td:not(:first-child) {
    border-left: 0;
    border-right: 0;
}

.table-cart tbody tr td:first-child,
.table-cart tbody tr th:first-child {
    border-radius: 5px 0 0 5px;
    border-right: 0;
}

.table-cart tbody tr td:last-child,
.table-cart tbody tr th:last-child {
    border-radius: 0px 5px 5px 0px;
    border-right: 1px solid #E1E4E9;
}

.cart-page a {
    color: #0076E2;
}

.add-coupon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.add-coupon-input::placeholder {
    color: #6B6B6B;
}

.total-price {
    width: 30%;
}

/* TABLE CART */

@media screen and (min-width: 768px) {
    .table-cart-area .table-header {
        display: flex;
        align-items: center;
    }

    .table-cart-area .table-header .table-header--title {
        width: 70%;
        font-weight: 500;
        font-size: 1.5rem;
        color: #000000;
        line-height: 29px;
    }

    .table-cart-area .table-header .table-header--action {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
    }

}

@media screen and (max-width: 768px) {
    .table-cart-area .table-header {
        display: block;
        align-items: center;
    }

    .table-cart-area .table-header .table-header--title {
        width: 100%;
        font-weight: 500;
        font-size: 1.5rem;
        color: #000000;
        line-height: 29px;
        padding: 1rem 0;
    }

    .table-cart-area .table-header .table-header--action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
    }

    .table-cart {
        min-width: 992px;
    }

    .add-coupon {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .add-coupon button {
        padding: 8px 24px !important
    }

    .total-price {
        width: 100%;
    }

}
