.my-order-widget-row {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.my-order-widget-row .widget-col {
    width: 20%;
}


@media only screen and (max-width: 768px) {
    .my-order-widget-row .widget-col {
        width: 100%;
    }

    .my-order-widget-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

}

@media only screen and (max-width: 576px) {
    .my-order-widget-row .widget-col {
        width: 100%;
    }

    .my-order-widget-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

}
