@media screen and (max-width: 992px) {
    .social-link {
        width: 40px;
        height: 40px;
     }
 }

@media screen and (min-width: 992px) {
    .social-link {
        width: 48px;
        height: 48px;
     }
 }


.social-link {
    background-color: #525252;
    color: var(--app-color-primary-white);
    padding: 0.25rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s;
    cursor: pointer;
}

.social-link:hover {
    background-color: var(--app-color-bg-3) !important;
}

.social-link i {
    font-size: 24px;
}