.get-instant-quote-form label {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 29px;
    color: #312F30
}

.get-instant-quote-form label:has(sub) {
    position: relative;
}

.get-instant-quote-form sub {
    position: absolute;
    right: -20px;
    top: 0;
    font-size: 1rem;
}


.get-instant-quote-form .form-children label {
    font-weight: 500;
    line-height: 19px;
    color: #6B6B6B;
    font-size: 1rem;
    white-space: nowrap;
}

.get-instant-quote-form .form-control,
.get-instant-quote-form .form-select {
    color: #000000;
    font-weight: 500;
    background-color: #FBFBFB;
    border: 1px solid #E7EAEF;
    border-radius: 5px;
    padding: 0.75rem;
}

.get-instant-quote-form .button[type="submit"] {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    padding: 1rem;
}

.get-instant-quote-form .form-range::-webkit-slider-thumb {
    background: #0076E2;
}

.get-instant-quote-form .form-range::-ms-thumb {
    background: linear-gradient(270deg, #9ABFD2 0%, rgba(177, 207, 223, 0.37) 100%);
}

.get-instant-quote-form .form-range::-webkit-slider-runnable-track {
    background: linear-gradient(270deg, #9ABFD2 0%, rgba(177, 207, 223, 0.37) 100%);
}

.unit {
    margin-top: 10px;
    border-bottom: 1px dotted #3d3d3d;
    width: fit-content;
}

.price {
    font-weight: bold;
    line-height: '38px';
    font-family: 'barlow';
    color: #ff6b00;
    font-size: 28px;
}

.mr-1 {
    margin-right: 4px;
}

.form-control.is-valid:focus,
.was-validated :valid.form-control {
    /* border-color: #DDDDDD !important; */
    background-image: inherit !important;
    box-shadow: inherit !important;
}

/* .form-select.is-valid, .was-validated .form-select:valid {
    border-color: #DDDDDD !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e') !important;
    box-shadow: inherit !important;
} */
