.order-widget {
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
}

.order-widget:hover {
    background-color: var(--app-color-bg-1);
}

.order-widget.active {
    background: #DAEAF2;
}

.order-widget .order-widget__total {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 29px;
    color: var(--app-color-text-1);
}

.order-widget.active .order-widget__total {
    color: var(--app-color-primary-blue);
}

.order-widget .order-widget__title {
    line-height: 140%;
    color: var(--app-color-text-1);
}
