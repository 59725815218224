table {
    border-collapse: collapse;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    font-family: Barlow;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}

.table.pl-0 td {
    padding: 0 2rem 0 0;
}

.table.no-border td {
    border: none !important;
    ;
}

.table.min-800 {
    min-width: 800px;
}

.materials-table thead {
    background: #2771B6;
    color: white
}

.materials-table thead th {
    padding: 12px;
}

.materials-table tbody tr {
    border-bottom: 1px solid #312F30;
    border-left: 1px solid #312F30;
    border-right: 1px solid #312F30;
}

.materials-table tbody tr:last-child {
    border-radius: 0 0 8px 8px;
}

.materials-table td {
    vertical-align: middle;
}

.material-detail-table thead {
    background: #2771B6;
    color: white
}

.material-detail-table thead th {
    padding: 12px;
    border-bottom: none;
}

/* .material-detail-table tbody tr {
    border-bottom: 1px solid #312F30;
    border-left: 1px solid #312F30;
    border-right: 1px solid #312F30;
} */

.material-detail-table tbody tr td:first-of-type {
    border-left-width: 1px;
    border-color: inherit;
}

.material-detail-table tbody tr td:last-of-type {
    border-right-width: 1px;
    border-color: inherit;
}

.material-detail-table td {
    vertical-align: middle;
}
