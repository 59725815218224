button.button {
    padding: 0.4375rem 1.625rem;
    border-radius: 0.5rem;
    border: 0;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.25s;
}

button.button:hover {
    opacity: 0.8;
}

button.button.link {
    background-color: #525252;
    color: var(--app-color-primary-white);
}

.button.circle {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button.circle i {
    font-size: 24px;
}

button.button.primary {
    background: var(--app-color-primary-blue);
}

button.button.white {
    background: #fff;
    color: var(--app-color-primary-blue);
}

button.button.white-1 {
    background: #F2F2F2;
    color: var(--app-color-primary-blue);
}

button.button.grey {
    background: #F2F2F2;
    color: var(--app-color-primary-blue);
}

button.button.outline-dark {
    background: transparent;
    color: #6B6B6B;
    border: 1px solid #6B6B6B;
}

/* SIZE */
@media screen and (min-width: 1200px) {
    button.button.lg {
        padding: 15px 69px;
        font-size: 20px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    button.button.lg {
        padding: 15px 55px;
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    button.button.lg {
        padding: 15px 40px;
        font-size: 20px;
    }
}


@media screen and (max-width: 767px) {
    button.button.lg {
        padding: 15px 30px;
        font-size: 18px;
    }
}


button.button-option {
    background: rgb(218, 232, 239);
    border-radius: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: rgb(49, 47, 48);
    border: none;
    margin-bottom: 20px;
}

button.button:disabled {
    opacity: 0.7;
    background-color: #ddd;
}


/* BUTTON QUANTITY */
.button-quantity button.button {
    width: 40px;
    height: 40px;
    padding: 0 !important;
}

.button-quantity button.button i {
    font-size: 16px;
}

.button-quantity input {
    width: 85px;
    border: 0;
    text-align: center;
    background: transparent;
}
