/* The side navigation menu */

.sidenav-toggle {
cursor: pointer;
}

.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Stay on top */
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all 0.4s;
}

.sidenav.left {
  top: 0;
  left: 0;
}

.sidenav.right {
  top: 0;
  right: 0;
}



.sidenav .sidenav-content{
   padding: 1rem
}
  
  /* The navigation menu links */
  .sidenav .closebtn {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a.closebtn:hover {
    color: var(--app-color-primary-dark);
  }
  
  /* Position and style the close button (top right corner) */
  .sidenav .closebtn {
    /* position: absolute; */
    text-align: right;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    padding-right: 1rem;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a.closebtn {font-size: 18px;}
  }

  @media screen and (max-width: 768px) {
    .sidenav.show{
      width: 50%;
    }
  }

  @media screen and (min-width: 768px) {
    .sidenav.show{
      width: 40%;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.3;
    z-index: 999;
  }